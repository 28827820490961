
function About() { 
	return( 
	<>
      <section class="prt-row clearfix">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    
                    <div class="section-title mb-0">
                        <div class="title-header">
                            <h3>Welcome to Pixalive Ventures!</h3>
                            <h2 class="title">From Vision to Victory: Your Comprehensive Support Partner</h2>
                        </div>
                        <div class="title-desc">
                            <p class="pb-0">Pixalive Ventures is a technology-focused firm deeply committed to startups. Our mission is to empower visionary entrepreneurs by offering a holistic range of support. </p>
                            <br/>
                            <p class="pb-0">From essential guidance and cutting-edge technology solutions to strategic marketing, robust resource support, expertise in scaling businesses, adept legal and compliance assistance, and facilitation of funding.</p>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <div class="res-767-mt-30">
                        <div class="about-2 position-relative">
                            <img width="646" height="380" class="img-fluid" src="./assets/images/about-us.jpg" alt="image"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    {/* <section class="prt-row fid-section_1 clearfix">
        <div class="container">
            <div class="row aos-init aos-animate" data-aos="fade-up" data-aos-duration="200">
                <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                    <div class="prt-fid inside style1">
                        <div class="prt-fid-contents text-left">
                            <h4 class="prt-fid-inner">
                                <span>35</span>
                            </h4>
                            <h3 class="prt-fid-title">Finished Projects</h3>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                    <div class="prt-fid inside style1">
                        <div class="prt-fid-contents text-left">
                            <h4 class="prt-fid-inner">
                                <span class="numinate completed">4+</span>
                            </h4>
                            <h3 class="prt-fid-title">Years of Experiance</h3>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                    <div class="prt-fid inside style1">
                        <div class="prt-fid-contents text-left">
                            <h4 class="prt-fid-inner">
                                <span class="numinate completed">7</span>
                            <span>K</span>
                            </h4>
                            <h3 class="prt-fid-title">Happy Clients</h3>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                    <div class="prt-fid inside style1">
                        <div class="prt-fid-contents text-left">
                            <h4 class="prt-fid-inner">
                                <span  class="numinate completed">7</span>
                            </h4>
                            <h3 class="prt-fid-title">Supported Startups</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> */}

        
  </> 
  ); 
  } 
  export default About;