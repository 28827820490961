
import Header from '../../components/Header'; 
import Footer from '../../components/Footer';
import Support from './ServiceSupport';

function Technology() { 
	return( 
	<>
    
    <Header />

        <div class="prt-page-title-row">
            <div class="prt-page-title-row-inner">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-12">
                            <div class="prt-page-title-row-heading">
                                <div class="page-title-heading">
                                    <h2 class="title">Services</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>                    
        </div>

        <div class="site-main">
        <div class="prt-row sidebar prt-sidebar-left clearfix">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4 widget-area sidebar-left prtcol-bgcolor-yes prt-bg prt-left-span bg-base-grey">
                            <div class="prt-col-wrapper-bg-layer prt-bg-layer"></div>
                            <aside class="widget widget-nav-menu with-title">
                                {/* <div class="widget-title">
                                    <h3>Our Services</h3>
                                </div> */}
                                <ul>
                                    <li class="active"><a href="/Technology">Technology</a></li>
                                    <li><a href="/MarketingBranding">Marketing & Branding</a></li>
                                    <li><a href="/ResourcesSupport">Resources Support</a></li>
                                    <li><a href="/LegalCompliance">Legal & Compliance</a></li>
                                    <li><a href="/Funding">Funding</a></li>
                                    <li><a href="/ScalingBusinesses">Scaling Businesses</a></li>
                                </ul>
                            </aside>  
                            <aside class="widget widget-download with-title">
                                 <div class="text-base-white prt-bg prt-col-bgimage-yes col-bg-img-one">
                                    <div class="prt-col-wrapper-bg-layer prt-bg-layer">
                                        <div class="prt-col-wrapper-bg-layer-inner"></div>
                                    </div>
                                </div>
                            </aside>    
                        </div>
                        <div class="col-lg-8 content-area">
                            <div class="prt-service-single-content-area"> 
                                <div class="prt-service-description">
                                    <h3>Technology</h3>
                                    <div class="row mt-40">
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                            
                                            <div class="prt-bg prt-col-bgimage-yes col-bg-img-three z-index-2">
                                                <div class="prt-col-wrapper-bg-layer prt-bg-layer">
                                                    <img class="img-fluid" src="../assets/images/technology.jpg" alt="bg-image"/>                                        
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6">
                                            <div class="res-767-mt-20">
                                                <div class="layer-content d-flex flex-column justify-content-center">
                                                <p>We assist startups in building MVPs (Minimum Viable Products) that enable them to test and refine their ideas, leading to a stronger market entry.</p>
                                   
                                    <p> Technological expenses play a major role
in the startup's journey to convert their ideas into an MVP.</p>
<p>Starting a business is a dream for people in India, whereas technology should not be a barrier or
burden for them to develop their product.</p> 
<p>Pixalive helps them by providing robust technology
support at a nominal cost in the market through its strong technical workforce.</p>
                                                    {/* <ul class="prt-list fs-18 prt-list-style-icon">
                                                        <li>
                                                            <i class="ti-angle-right"></i>
                                                            <div class="prt-list-li-content">Technology 1</div>
                                                        </li>
                                                        <li>
                                                            <i class="ti-angle-right"></i>
                                                            <div class="prt-list-li-content">Technology 2</div>
                                                        </li>
                                                        <li>
                                                            <i class="ti-angle-right"></i>
                                                            <div class="prt-list-li-content">Technology 3</div>
                                                        </li>
                                                        <li>
                                                            <i class="ti-angle-right"></i>
                                                            <div class="prt-list-li-content">Technology 4</div>
                                                        </li>
                                                    </ul> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div> 
      <Support/>
        </div>


    <Footer/>

    </> 
    ); 
  } 
  export default Technology;