
import Header from '../../components/Header'; 
import Footer from '../../components/Footer';
// import Team from '../../components/Team';

function AboutUs() { 
	return( 
	<>
    
    <Header />

    <div class="prt-page-title-row">
            <div class="prt-page-title-row-inner">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-12">
                            <div class="prt-page-title-row-heading">
                                <div class="page-title-heading">
                                    <h2 class="title">About Us</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>                    
        </div>

        <div class="site-main"> 
            <section class="prt-row clearfix">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-xl-6 col-lg-6 col-md-12">
                            <div> 
                                <div class="pr-25 res-1199-pr-0">
                                    <img width="610" height="581" class="img-fluid border-rad_50" src="../assets/images/about-us.jpg" alt="Image"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-12">
                            <div class="res-991-mt-30"> 
                                <div class="section-title">
                                    <div class="title-heade">
                                        <h2 class="title">From Ideas to Success: Your All-in-One Support Partner.</h2>
                                        <p>Pixalive Ventures is a technology-focused firm deeply committed to startups. Our mission is to empower visionary entrepreneurs by offering a holistic range of support. From essential guidance and cutting-edge technology solutions to strategic marketing, robust resource support, expertise in scaling businesses, adept legal and compliance assistance, and facilitation of funding.</p>
                     <p>At Pixalive Ventures, we are passionate about supporting startups and driving innovation. We are committed to being your trusted partner on your path to success.</p>
                     
                                    </div>
                                </div> 
                                <div class="prt-tabs prt-tab-style-01">
                                    <ul class="tabs">
                                        <li class="tab active"><a href="#">Vision</a></li>
                                        <li class="tab"><a href="#">Mission</a></li>
                                    </ul>
                                    <div class="content-tab"> 
                                        <div class="content-inner active">
                                            <p>Our vision is to build long-term partnerships with startups, helping them turn their ideas into
successful businesses.</p>
                                        </div> 
                                        <div class="content-inner">
                                            <p>Our mission is to provide comprehensive support to startups across all significant aspects,
guiding them towards achieving resounding success.</p>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            {/* <Team /> */}

            <section class="prt-row clearfix prt-row-nw">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-12"> 
                            <div class="clients-marquebox-1 ">
                                <div class="clients-marquebox-wrapper">
                                    <div class="prt-marqueboxes-wrapper prt-marquebox-style1">
                                        <ul class="icon-list-items marquebox-list mt-0">
                                            <li class="icon-list-item">
                                                <div class="client-thumbnail">
                                                    <a href="#"><img width="300" height="53" class="img-fluid" src="../assets/images/kri-01.jpg" alt="image"/></a>
                                                </div>
                                            </li>
                                            <li class="icon-list-item">
                                                <div class="client-thumbnail">
                                                    <a href="#"><img width="262" height="53" class="img-fluid" src="../assets/images/medalive.jpg" alt="image"/></a>
                                                </div>
                                            </li>
                                            <li class="icon-list-item">
                                                <div class="client-thumbnail">
                                                    <a href="#"><img width="220" height="53" class="img-fluid" src="../assets/images/SoftDaddy.png" alt="image"/></a>
                                                </div>
                                            </li>
                                            <li class="icon-list-item">
                                                <div class="client-thumbnail">
                                                    <a href="#"><img width="210" height="47" class="img-fluid" src="../assets/images/SexifyLogo.png" alt="image"/></a>
                                                </div>
                                            </li>
                                            <li class="icon-list-item">
                                                <div class="client-thumbnail">
                                                    <a href="#"><img width="300" height="53" class="img-fluid" src="../assets/images/luvkart.png" alt="image"/></a>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            
                </div>
            </section>

        </div>
      
    <Footer/>

    </> 
    ); 
  } 
  export default AboutUs;