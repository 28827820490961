
function Banner() { 
	return( 
	<>
    <section class="prt-row bg-base-dark prt-bg prt-bgimage-yes bg-img2 banner-section clearfix slide">
      <div class="prt-row-wrapper-bg-layer prt-bg-layer bg-base-dark"></div>
        <div class="container">
            <div class="row ">
                <div class="slick_slider slick-arrow-style1 row-equal-height" data-slick='{"slidesToShow": 1, "slidesToScroll": 1, "arrows":false, "dots":true, "autoplay":true, "infinite":true, "responsive": [{"breakpoint":1199,"settings":{"slidesToShow": 1,"arrows":false,"autoplay":true}}, {"breakpoint":778,"settings":{"slidesToShow": 1,"arrows":false}}, {"breakpoint":611,"settings":{"slidesToShow": 1}}]}'>
                    <div class="col-lg-8 m-auto">
                        <div class="about-content text-center" data-aos="fade-up" data-aos-duration="1500">                      
                            <div class="section-title title-style-center_text">
                                <div class="title-header title-header-slider ">
                                    {/* <img class="img-fluid" src="./assets/images/pixalive-digitals.svg" alt="image"/> */}
                                    <h3>Pixalive Ventures</h3>
                                    <h2 class="title">From Ideas to Success: Your All-in-One Support Partner</h2>
                                    <p>We offer comprehensive support, spanning ideation, technical assistance, digital marketing, legal compliance, and financial guidance</p>
                                </div>
                            </div>
                            <div data-aos="fade-up" data-aos-duration="1500">
                                <a class="prt-btn prt-btn-size-md prt-btn-shape-rounded prt-btn-style-border prt-btn-color-white mt-35 " href="/Technology">Discover More</a>
                            </div>
                        </div>
                    </div>
                    {/* <div class="col-lg-8 m-auto">
                        <div class="about-content text-center" data-aos="fade-up" data-aos-duration="1500">                      
                            <div class="section-title title-style-center_text">
                                <div class="title-header title-header-slider ">
                                <img class="img-fluid" src="./assets/images/talent.svg" alt="image"/>
                                 
                                    <h2 class="title">Getting Projects & showcasing the Strength of Our Workforce</h2>
                                </div>
                            </div>
                            <div data-aos="fade-up" data-aos-duration="1500">
                                <a class="prt-btn prt-btn-size-md prt-btn-shape-rounded prt-btn-style-border prt-btn-color-white mt-35 " href="/Talents">Discover More</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8 m-auto">
                        <div class="about-content text-center" data-aos="fade-up" data-aos-duration="1500">                      
                            <div class="section-title title-style-center_text">
                                <div class="title-header title-header-slider ">
                                <img class="img-fluid" src="./assets/images/pixalive-labs.svg" alt="image"/>
                                   
                                    <h2 class="title">Nurturing a Robust Workforce through Training</h2>
                                </div>
                            </div>
                            <div data-aos="fade-up" data-aos-duration="1500">
                                <a class="prt-btn prt-btn-size-md prt-btn-shape-rounded prt-btn-style-border prt-btn-color-white mt-35 " href="/Labs">Discover More</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8 m-auto">
                        <div class="about-content text-center" data-aos="fade-up" data-aos-duration="1500">                      
                            <div class="section-title title-style-center_text">
                                <div class="title-header title-header-slider ">
                                <img class="img-fluid" src="./assets/images/pixalive-works.svg" alt="image"/>
                                    
                                    <h2 class="title">Seamless Solutions, Limitless Possibilities: Your SaaS Partner</h2>
                                </div>
                            </div>
                            <div data-aos="fade-up" data-aos-duration="1500">
                                <a class="prt-btn prt-btn-size-md prt-btn-shape-rounded prt-btn-style-border prt-btn-color-white mt-35 "  href="/Works">Discover More</a>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    </section>
    
        
  </> 
  ); 
  } 
  export default Banner;