
import Header from '../../components/Header'; 
import Footer from '../../components/Footer';

function ContactUs() { 
	return( 
	<>
    
    <Header />
        <div class="prt-page-title-row">
            <div class="prt-page-title-row-inner">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-12">
                            <div class="prt-page-title-row-heading">
                                <div class="page-title-heading">
                                    <h2 class="title">Contact Us</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>                    
        </div> 
        <div class="site-main">
            <section class="prt-row bg-layer-equal-height clearfix">
                <div class="container"> 
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="contentmap"> 
                                <div class="section-title">
                                    <div class="title-header">
                                        <h2 class="title">Contact Us</h2>
                                    </div>
                                    <div class="title-desc">
                                        <p>You can reach us by phone, email, or by filling out the form below. Our team is available to answer any questions you may have and discuss how our services can benefit your business. Thank you for considering our business services.</p>
                                    </div>
                                    <div class="map-contect mt-15">
                                        <div class="mb-15"><a href="#">+91 87785 84566</a></div>
                                        <div><a href="#">info@pixaliveventures.com</a></div>
                                    </div>
                                    <div class="desc res-575-mt-15">
                                        <p class="mb-5">Third floor, No 35/2 Konappana Agrahara, Hosur Rd, 2nd phase, Electronic City, Karnataka 560100</p>
                                    </div>
                                    <ul class="social-icons map-links mt-50">
                                        <li><a href="https://pixalive.me/" rel="noopener" target='_blank' aria-label="Pixalive">Pixalive</a></li>
                                        <li><a href="https://twitter.com/PixaliveV" target='_blank' rel="noopener" aria-label="twitter">Twitter</a></li>
                                        <li><a href="https://pixalive.me/" target='_blank'  rel="noopener" aria-label="linkedin">Linkedin</a></li>
                                        <li><a href="https://www.youtube.com/@PixaliveAp" target='_blank' rel="noopener" aria-label="Youtube">Youtube</a></li>
                                    </ul>
                                </div> 
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="map-contect-form position-relative ml-110 res-1199-ml-0 res-1199-mt-30">                                
                                <div class="section-title">
                                    <div class="title-header">
                                        <h2 class="title">Get a free quote</h2>
                                    </div>
                                </div> 
                                <form action="#" class="query_form wrap-form clearfix mt-45 res-575-mt-0 position-relative"  method="post">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <label>
                                                <span class="text-input"><input name="name" type="text"  placeholder="First Name" required="required"/></span>
                                            </label>
                                        </div>
                                        <div class="col-md-12">
                                            <label>
                                                <span class="text-input"><input name="email" type="text"  placeholder="Email Address" required="required"/></span>
                                            </label>
                                        </div>
                                        <div class="col-md-12">
                                            <label>
                                                <span class="text-input"><input name="phone" type="text"  placeholder="Phone" required="required"/></span>
                                            </label>
                                        </div>
                                        <div class="col-md-12">
                                            <label>
                                                <span class="text-input"><textarea name="message" rows="4" placeholder="Message goes here" required="required"></textarea></span>
                                            </label>
                                        </div>
                                        <div class="col-md-12">
                                           <button class="prt-btn prt-btn-size-sm prt-btn-shape-rounded prt-btn-style-border prt-btn-color-dark btn-hover mt-15" type="submit">Get started</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div id="google_map" class="google_map">
                <div class="map_container">
                    <div id="map">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15559.671290268201!2d77.6706383!3d12.8485841!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae6d95c77b9285%3A0x580316a77880fa5!2sPixalive!5e0!3m2!1sen!2sin!4v1704900918681!5m2!1sen!2sin" height="500" width="100%"></iframe>
                    </div>
                </div>
            </div>
        </div> 

        <Footer/>

    </> 
    ); 
  } 
  export default ContactUs;