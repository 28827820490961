
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./pages/Home/Home";
import Banner from "./pages/Home/Banner";
import About from "./pages/Home/About";
import Startups from "./pages/Home/Startups"; 
import SupportedStartups from "./pages/Home/SupportedStartups";
import Support from "./pages/Home/Support";
import ContactUs from "./pages/ContactUs/ContactUs";
import AboutUs from "./pages/AboutUs/AboutUs";
import Blog from "./pages/Blog/Blog";
import Services from "./pages/Services/Technology";
import Technology from "./pages/Services/Technology";
import MarketingBranding from "./pages/Services/MarketingBranding";
import ResourcesSupport from "./pages/Services/ResourcesSupport"; 
import Funding from "./pages/Services/Funding";
import ScalingBusinesses from "./pages/Services/ScalingBusinesses";
import LegalCompliance from "./pages/Services/LegalCompliance";
import ServiceSupport from "./pages/Services/ServiceSupport"; 


const routes = (
  <Router>    
    <Routes>
      <Route path='/' element={<Home/>} />
      <Route path='/Banner' element={<Banner/>} />
      <Route path='/About' element={<About/>} />
      <Route path='/Startups' element={<Startups/>} /> 
      <Route path='/SupportedStartups' element={<SupportedStartups/>} />
      <Route path='/Support' element={<Support/>} />
      <Route path='/AboutUs' element={<AboutUs/>} />
      <Route path='/ContactUs' element={<ContactUs/>} />
      <Route path='/Blog' element={<Blog/>} />
      <Route path='/Services' element={<Services/>} />
      <Route path='/Technology' element={<Technology/>} />
      <Route path='/MarketingBranding' element={<MarketingBranding/>} />  
      <Route path='/ResourcesSupport' element={<ResourcesSupport/>} />   
      <Route path='/Funding' element={<Funding/>} />  
      <Route path='/ScalingBusinesses' element={<ScalingBusinesses/>} />  
      <Route path='/LegalCompliance' element={<LegalCompliance/>} />  
      <Route path='/ServiceSupport' element={<ServiceSupport/>} />   
      
    </Routes>
 </Router> )
function App() {
  return (
    <>
        {routes}  
    </>
  );
}

export default App;
