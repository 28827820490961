import React from 'react';

function  Footer () {
  return (
  <>
  
        <footer class="footer widget-footer  text-base-white clearfix">
            <div class="second-footer">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-5 widget-area">
                            <div class="widget widget_text clearfix">
                            {/* <h3 class="widget-title">Pixalive Ventures</h3> */}
                                <img id="logo-img"  class="img-fluid auto_size" src="./assets/images/logo-white.svg" alt="logo-img"/>
                            
                                <p>We offer comprehensive support, spanning ideation, technical assistance, digital marketing, legal compliance, and financial guidance.</p>
                                <div class="widget_social_wrapper social-icons">
                                    <ul class="social-icons">
                                        <li>Social Share:</li>
                                        <li><a href="https://pixalive.me/" target='_blank' rel="noopener"><img class="pix-img" src="./assets/images/Logo-Icon-White.png" alt="logo-img"/></a></li>
                                        <li><a href="https://twitter.com/PixaliveV" target='_blank' rel="noopener" aria-label="twitter"><i class="icon-twitter"></i></a></li>
                                        <li><a href="https://www.linkedin.com/in/pixalive-ventures-9811a9286/" target='_blank' aria-label="linkedin"><i class="icon-linkedin"></i></a></li>
                                        <li><a href="https://www.youtube.com/@PixaliveApp" target='_blank' aria-label="linkedin"><i class="icon-youtube"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4 widget-area">
                            <div class="widget widget_cta clearfix">
                                <h3 class="widget-title">Get in touch</h3>
                                <div class="footer-content-call">
                                    <h3>Our phone number</h3>
                                    <p><a href="tel:+91 87785 84566">+91 87785 84566</a></p>
                                </div>
                                <div class="footer-content-location">
                                    <h3>Our locations</h3>
                                    <p><a href="#">Third floor, No 35/2 Konappana Agrahara, Hosur Rd, 2nd phase, Electronic City, Karnataka 560100</a></p>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 widget-area">
                            <div class="widget link-widget clearfix">
                               <h3 class="widget-title">Quick links!</h3>
                               <ul class="menu-footer-link">
                                    <li><a href="/AboutUs">About Us</a></li>
                                    {/* <li><a href="/Talents">Divisions</a></li> */}
                                    <li><a href="/Technology">Services</a></li>
                                    <li><a href="/ContactUs">Contact Us</a></li>
                                    {/* <li><a href="#">Privacy policy</a></li> */}
                                </ul>
                            </div>
                        </div> 
                        
                    </div>
                </div>
            </div>
            <div class="bottom-footer-text">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-12">
                            <span class="copyright">Copyright © 2023 <a href="#">Pixalive</a> Designed by <a href="#">Pixalive</a></span>
                        </div>
                    </div>
                </div>
            </div>   
        </footer>


  </>
);
}

export default Footer;