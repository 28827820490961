
import Header from '../../components/Header'; 
import Footer from '../../components/Footer';

function Blog() { 
	return( 
	<>
    
    <Header />

        <div class="prt-page-title-row">
            <div class="prt-page-title-row-inner">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-12">
                            <div class="prt-page-title-row-heading">
                                <div class="page-title-heading">
                                    <h2 class="title">Blog</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>                    
        </div>

        <div class="site-main">
            <section class="prt-row blog-section bg-base-grey clearfix">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="section-title title-style-center_text">
                                <div class="title-header">
                                    <h3>Blog</h3>
                                    <h2 class="title">Latest News & Updates</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="featured-imagebox featured-imagebox-post style1">
                                <div class="featured-thumbnail">
                                    <img  class="img-fluid" src="../assets/images/blog-01-1200x560.jpg" alt="Image"/>
                                </div>
                                <div class="featured-content">
                                    <div class="featured-content-inner">
                                        <div class="post-header">
                                            
                                            {/* <div class="post-meta">
                                                <span class="prt-meta-line">Admin</span>
                                                <span class="prt-meta-line">0 Comments</span>
                                            </div> */}
                                            <div class="post-title featured-title">
                                                <h3><a href="/BlogDetail">5 ways Business Services Can Strealine Your Operations</a></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            
                            <div class="featured-imagebox featured-imagebox-post style1">
                                <div class="featured-thumbnail">
                                    <img  class="img-fluid" src="../assets/images/blog-01-1200x560.jpg" alt="Image"/>
                                </div>
                                
                                <div class="featured-content">
                                    <div class="featured-content-inner">
                                        <div class="post-header">
                                            
                                            {/* <div class="post-meta">
                                                <span class="prt-meta-line">Admin</span>
                                                <span class="prt-meta-line">0 Comments</span>
                                            </div> */}
                                            <div class="post-title featured-title">
                                                <h3><a href="/BlogDetail">Revolutionize Your Business with Changing Services</a></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            
                            <div class="featured-imagebox featured-imagebox-post style1">
                                <div class="featured-thumbnail">
                                    <img  class="img-fluid" src="../assets/images/blog-01-1200x560.jpg" alt="Image"/>
                                </div>
                                
                                <div class="featured-content">
                                    <div class="featured-content-inner">
                                        <div class="post-header">
                                            
                                            {/* <div class="post-meta">
                                                <span class="prt-meta-line">Admin</span>
                                                <span class="prt-meta-line">0 Comments</span>
                                            </div> */}
                                            <div class="post-title featured-title">
                                                <h3><a href="/BlogDetail">Get Innovative Business Services for the Growth</a></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            
                            <div class="featured-imagebox featured-imagebox-post style1">
                                <div class="featured-thumbnail">
                                    <img  class="img-fluid" src="../assets/images/blog-01-1200x560.jpg" alt="Image"/>
                                </div>
                                
                                <div class="featured-content">
                                    <div class="featured-content-inner">
                                        <div class="post-header">
                                            
                                            <div class="post-title featured-title">
                                                <h3><a href="/BlogDetail">Get the Efficient Solutions for the Business Need</a></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            
                            <div class="featured-imagebox featured-imagebox-post style1">
                                <div class="featured-thumbnail">
                                    <img  class="img-fluid" src="../assets/images/blog-01-1200x560.jpg" alt="Image"/>
                                </div>
                                
                                <div class="featured-content">
                                    <div class="featured-content-inner">
                                        <div class="post-header">
                                            
                                            <div class="post-title featured-title">
                                                <h3><a href="/BlogDetail">Unlocking Growth Potential with Business Service </a></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            
                            <div class="featured-imagebox featured-imagebox-post style1">
                                <div class="featured-thumbnail">
                                    <img  class="img-fluid" src="../assets/images/blog-01-1200x560.jpg" alt="Image"/>
                                </div>
                                
                                <div class="featured-content">
                                    <div class="featured-content-inner">
                                        <div class="post-header">
                                            <div class="post-title featured-title">
                                                <h3><a href="/BlogDetail">Maximizie Your Business Potential Innovative Solutions</a></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>


    <Footer/>

    </> 
    ); 
  } 
  export default Blog;