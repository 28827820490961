
function SupportedStartups() { 
	return( 
	<>  
        <section class="prt-row content-section clearfix">
            <div class="container">
                <div class="row row-equal-height">
                    <div class="section-title title-style-center_text mb-20">
                        <div class="title-header">
                            <h2 class="title">MVP Startups</h2>
                            <h3>We are proud to have supported a diverse range of startups in their entrepreneurial journey. Some of the startups we have collaborated with include</h3>
                        </div>
                    </div>
                    <div class="slick_slider_logo">                    
                        <div class="featured-icon-box icon-align-top-content style1">
                            <div class="featured-icon">
                                <div class="featured-img prt-icon prt-icon_element-onlytxt prt-icon_element-size-sm prt-icon_element-color-darkcolor">
                                    <img src="../assets/images/kri-01.jpg" class="img-fluid" alt="image"/>   
                                </div>
                            </div>
                            <div class="featured-content">
                                <div class="featured-title">
                                    <h3><a href="#">Krishi1</a></h3>
                                </div>
                                <div class="featured-desc">
                                    <p>An agricultural startup focusing on the entire value chain, from soil to market, to revolutionize the agriculture industry.</p>
                                </div>
                                <a class="prt-btn prt-btn-size-sm prt-btn-shape-rounded prt-btn-style-border prt-btn-color-dark" href="#">Know More</a>
                           </div>
                        </div>
                        
                        <div class="featured-icon-box icon-align-top-content style1">
                            <div class="featured-icon">
                                <div class="featured-img prt-icon prt-icon_element-onlytxt prt-icon_element-size-sm prt-icon_element-color-darkcolor">
                                    <img src="../assets/images/medalive.jpg" class="img-fluid" alt="image"/>   
                                </div>
                            </div>
                            <div class="featured-content">
                                <div class="featured-title">
                                    <h3><a href="#">Medalive</a></h3>
                                </div>
                                <div class="featured-desc">
                                    <p>A medical social media platform that addresses medical issues, offers an online pharmacy, and provides access to expert doctors.</p>
                                </div>
                                <a class="prt-btn prt-btn-size-sm prt-btn-shape-rounded prt-btn-style-border prt-btn-color-dark" href="#">Know More</a>
                           </div>
                        </div>
                        
                        <div class="featured-icon-box icon-align-top-content style1">
                            <div class="featured-icon">
                                <div class="featured-img prt-icon prt-icon_element-onlytxt prt-icon_element-size-sm prt-icon_element-color-darkcolor">
                                    <img src="../assets/images/SoftDaddy.png" class="img-fluid" alt="image"/>   
                                </div>
                            </div>
                            <div class="featured-content">
                                <div class="featured-title">
                                    <h3><a href="#">SoftDaddy</a></h3>
                                </div>
                                <div class="featured-desc">
                                    <p>A marketplace that specializes in selling ready-made software, empowering businesses with efficient and cost-effective solutions.</p>
                                </div>
                                <a class="prt-btn prt-btn-size-sm prt-btn-shape-rounded prt-btn-style-border prt-btn-color-dark" href="#">Know More</a>
                           </div>
                        </div>
                        
                        <div class="featured-icon-box icon-align-top-content style1">
                            <div class="featured-icon">
                                <div class="featured-img prt-icon prt-icon_element-onlytxt prt-icon_element-size-sm prt-icon_element-color-darkcolor">
                                    <img src="../assets/images/SexifyLogo.png" class="img-fluid" alt="image"/>   
                                </div>
                            </div>
                            <div class="featured-content">
                                <div class="featured-title">
                                    <h3><a href="#">Sexify</a></h3>
                                </div>
                                <div class="featured-desc">
                                    <p>An inclusive sexual wellness marketplace that provides a wide range of products and services to enhance overall well-being.</p>
                                </div>
                                <a class="prt-btn prt-btn-size-sm prt-btn-shape-rounded prt-btn-style-border prt-btn-color-dark" href="#">Know More</a>
                           </div>
                        </div>
                        <div class="featured-icon-box icon-align-top-content style1">
                            <div class="featured-icon">
                                <div class="featured-img prt-icon prt-icon_element-onlytxt prt-icon_element-size-sm prt-icon_element-color-darkcolor">
                                    <img src="../assets/images/luvkart.png" class="img-fluid" alt="image"/>   
                                </div>
                            </div>
                            <div class="featured-content">
                                <div class="featured-title">
                                    <h3><a href="#">Luvkart</a></h3>
                                </div>
                                <div class="featured-desc">
                                    <p>A direct-to-consumer marketplace that focuses on fashion, cosmetics, and lifestyle products. They curate a unique selection of products.</p>
                                </div>
                                <a class="prt-btn prt-btn-size-sm prt-btn-shape-rounded prt-btn-style-border prt-btn-color-dark" href="#">Know More</a>
                           </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    
  </> 
  ); 
  } 
  export default SupportedStartups;