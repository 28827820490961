
import Header from '../../components/Header'; 
import Footer from '../../components/Footer';
import Support from './ServiceSupport';

function MarketingBranding() { 
	return( 
	<>
    
    <Header />

        <div class="prt-page-title-row">
            <div class="prt-page-title-row-inner">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-12">
                            <div class="prt-page-title-row-heading">
                                <div class="page-title-heading">
                                    <h2 class="title">Services</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>                    
        </div>

        <div class="site-main">
        <div class="prt-row sidebar prt-sidebar-left clearfix">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4 widget-area sidebar-left prtcol-bgcolor-yes prt-bg prt-left-span bg-base-grey">
                            <div class="prt-col-wrapper-bg-layer prt-bg-layer"></div>
                            <aside class="widget widget-nav-menu with-title">
                                {/* <div class="widget-title">
                                    <h3>Our Services</h3>
                                </div> */}
                                <ul>
                                    <li><a href="/Technology">Technology</a></li>
                                    <li class="active"><a href="/MarketingBranding">Marketing & Branding</a></li>
                                    <li><a href="/ResourcesSupport">Resources Support</a></li>
                                    <li><a href="/LegalCompliance">Legal & Compliance</a></li>
                                    <li><a href="/Funding">Funding</a></li>
                                    <li><a href="/ScalingBusinesses">Scaling Businesses</a></li>
                                </ul>
                            </aside>  
                            <aside class="widget widget-download with-title">
                                 <div class="text-base-white prt-bg prt-col-bgimage-yes col-bg-img-one">
                                    <div class="prt-col-wrapper-bg-layer prt-bg-layer">
                                        <div class="prt-col-wrapper-bg-layer-inner"></div>
                                    </div>
                                </div>
                            </aside>    
                        </div>
                        <div class="col-lg-8 content-area">
                            <div class="prt-service-single-content-area"> 
                                <div class="prt-service-description">
                                    <h3>Marketing & Branding</h3>
                                    
                                    <div class="row mt-40">
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                            
                                            <div class="prt-bg prt-col-bgimage-yes col-bg-img-three z-index-2">
                                                <div class="prt-col-wrapper-bg-layer prt-bg-layer">
                                                <img class="img-fluid" src="../assets/images/marketing.jpg" alt="bg-image"/>
                                        
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6">
                                            <div class="res-767-mt-20">
                                                <div class="layer-content d-flex flex-column justify-content-center">
                                                <p>The second major challenge faced by startups is marketing. Once the product is developed, it
needs the right platform to be sold or its services to be utilized. Our expertise in marketing helps
startups gain traction and visibility in the competitive market.</p>
<p> We develop customized strategies
to effectively promote your products or services and reach your target audience. Only then can it
enhance their company's value and lead them to seek funding.</p>
                                                   <p>One of the major advantages of choosing Pixalive is its own Social Media Application for
marketing, which proves to be crucial for startups to succeed. Additionally, even funded startups
utilize our Pixalive App for marketing.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      <Support/>
        </div>


    <Footer/>

    </> 
    ); 
  } 
  export default MarketingBranding;