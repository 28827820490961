import React from 'react';

function  Header () {
  return (
  <>
  
  <header id="masthead" class="header prt-header-style-01">
            <div id="site-header-menu" class="site-header-menu">
                <div class="site-header-menu-inner prt-stickable-header">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="site-navigation d-flex align-items-center justify-content-between">
                                    
                                    <div class="site-branding">
                                        <h1><a class="home-link" href="/" title="" rel="home">
                                            <img id="logo-img" height="40" width="180" class="img-fluid auto_size" src="./assets/images/logo-light.svg" alt="logo-img"/>
                                        </a></h1>
                                    </div>
                                    <div class="menu-link">
                                        <div class="btn-show-menu-mobile menubar menubar--squeeze">
                                            <span class="menubar-box">
                                                <span class="menubar-inner"></span>
                                            </span>
                                        </div>
                                        <nav class="main-menu menu-mobile" id="menu">
                                            <ul class="menu">
                                                <li class="mega-menu-item">
                                                    <a  href="/">Home</a>
                                                </li>
                                                <li class="mega-menu-item">
                                                    <a href="/AboutUs">About Us</a> 
                                                </li> 
                                                <li class="mega-menu-item">
                                                    <a href="/Technology">Services</a> 
                                                </li>
                                                {/* <li class="mega-menu-item">
                                                    <a href="/Blog">Blog</a> 
                                                </li>  */}
                                                <li class="mega-menu-item">
                                                    <a href="/ContactUs">Contact us</a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                    <div class="side-menu-container">
                                        <div class="side-menu"><a href="#"><i class="icon-menu"></i></a></div>
                                        
                                        <div class="side-overlay">
                                            <div class="side bg-base-dark">
                                                <a href="#" class="close-side"><i class="icon-close"></i></a>
                                                <div class="flotingbar-img mb-55">
                                                    
                                            <img id="logo-img" height="40" width="180" class="img-fluid auto_size" src="./assets/images/logo-white.svg" alt="logo-img"/>
                                                </div>
                                                <div class="flotingbar-title">
                                                    <h2>With Our Startups Support System</h2>
                                                    {/* <p>Pixalive Works, Pixalive Digitals, Pixalive Talents and Pixalive Labs we provide comprehensive support to startups across various areas.</p> */}
                                                </div>
                                                <div class="prt-detailss"> 
                                                    <ul>
                                                        <li>Phone: <br /><span><a href="tel:+91 87785 84566"> +91 87785 84566 </a></span></li>
                                                        <li>Email: <br /><span> <a href="mailto:#">info@pixaliveventures.com</a> </span></li>
                                                        <li>Address: <br /><span> 2nd Floor, No, 33, Chikkathogur Main Rd, near Poonam Furniture House, Pragathi Nagar, Basapura, Bengaluru, Karnataka 560100</span></li>
                                                    </ul>
                                                </div>
                                                <ul class="social-icons">
                                                    <li><a href="https://pixalive.me/" target='_blank' rel="noopener"><img class="pix-img" src="./assets/images/Logo-Icon-White.png" alt="logo-img"/></a></li>
                                                    <li><a href="https://twitter.com/PixaliveV" target='_blank' rel="noopener" aria-label="twitter"><i class="icon-twitter"></i></a></li>
                                                    <li><a href="https://www.linkedin.com/in/pixalive-ventures-9811a9286/" target='_blank' aria-label="linkedin"><i class="icon-linkedin"></i></a></li>
                                                    <li><a href="https://www.youtube.com/@PixaliveApp" target='_blank' aria-label="linkedin"><i class="icon-youtube"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>


  </>
);
}

export default Header;