
function ServiceSupport() { 
	return( 
	<>  
    
    <section class="prt-row bg-img6 prt-bgimage-yes prt-bg bg-base-dark-1 clearfix">
                <div class="prt-row-wrapper-bg-layer prt-bg-layer bg-base-dark"></div>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-7 m-auto">
                            <div class="section-title title-style-center_text">
                                <div class="title-header text-base-white">
                                    <h3>Bringing dreams into reality</h3>
                                    <h2 class="title">Achieving <span class="text-base-skin">ambition</span> is possible</h2>
                                </div>
                            </div>
                            <div class="mt-35 d-flex align-items-center justify-content-center">
                                <a class="prt-btn prt-btn-size-md prt-btn-shape-rounded prt-btn-style-fill prt-btn-color-whitecolor" href="/ContactUs">Contact Us</a>
                                <div class="prt-call-to-arrow">
                                    <img width="102" height="78" src="../assets/images/arrow.png" class="img-fluid" alt="image"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    
  </> 
  ); 
  } 
  export default ServiceSupport;