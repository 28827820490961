
import Footer from '../../components/Footer';
import Header from '../../components/Header';  
import About from './About';
import Banner from './Banner'; 
import Startups from './Startups';
import Support from './Support';
import SupportedStartups from './SupportedStartups';
function Home() { 
	return( 
	<>
    <Header />

    <div class="site-main">
      <Banner/>
      <About/> 
      <Startups/>
      <SupportedStartups/>
      <Support/>
      {/* <Team/> */}
    </div>
    <Footer/>
    
        
  </> 
  ); 
  } 
  export default Home;