
function Startups() { 
	return( 
	<>  
    
    <section class="prt-row bg-img7 prt-bgimage-yes prt-bg bg-base-dark clearfix">
        <div class="prt-row-wrapper-bg-layer prt-bg-layer bg-base-dark"></div>
        <div class="container">
            <div class="row">
                <div class="col-lg-7 m-auto">
                    <div class="section-title title-style-center_text">
                        <div class="title-header">
                            <h2 class="title">Avail our services & Grow your startup</h2>
                        </div>
                    </div>
                </div> 
            </div>
            <div class="row row-equal-height mt-20">
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="200">
                    
                    <div class="featured-icon-box icon-align-top-content style2">
                        <div class="featured-icon">
                            <div class="prt-icon prt-icon_element-onlytxt prt-icon_element-size-sm prt-icon_element-color-whitecolor">
                            <i class="flaticon flaticon-intersection"></i>
                            </div>
                        </div>
                        <div class="featured-content">
                            <div class="featured-title">
                                <h3><a href="#">Technology</a></h3>
                            </div>
                            <div class="featured-desc">
                                <p>We assist startups in building MVPs (Minimum Viable Products) that enable themto test andrefine their ideas, leading to a stronger market entry.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="400">
                    
                    <div class="featured-icon-box icon-align-top-content style2">
                        <div class="featured-icon">
                            <div class="prt-icon prt-icon_element-onlytxt prt-icon_element-size-sm prt-icon_element-color-whitecolor">
                            <i class="flaticon flaticon-statistics"></i>
                            </div>
                        </div>
                        <div class="featured-content">
                            <div class="featured-title">
                                <h3><a href="#">Marketing & Branding</a></h3>
                            </div>
                            <div class="featured-desc">
                                <p>Our expertise in marketing helps startups gain traction and visibility in the competitive market. We develop customized strategies to effectively promote your products or services and reachyour target audience.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="600">
                    
                    <div class="featured-icon-box icon-align-top-content style2">
                        <div class="featured-icon">
                            <div class="prt-icon prt-icon_element-onlytxt prt-icon_element-size-sm prt-icon_element-color-whitecolor">
                            <i class="flaticon flaticon-cooperation"></i>
                            </div>
                        </div>
                        <div class="featured-content">
                            <div class="featured-title">
                                <h3><a href="#">Resources support</a></h3>
                            </div>
                            <div class="featured-desc">
                                <p>By providing technical resources, startups can hire skilled candidates, saving costs andtime. This also streamlines employee training and adaptation to the corporate structure.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="800">
                    
                    <div class="featured-icon-box icon-align-top-content style2">
                        <div class="featured-icon">
                            <div class="prt-icon prt-icon_element-onlytxt prt-icon_element-size-sm prt-icon_element-color-whitecolor">
                            <i class="flaticon flaticon-consultation"></i>
                            </div>
                        </div>
                        <div class="featured-content">
                            <div class="featured-title">
                                <h3><a href="#">Legal & Compliance</a></h3>
                            </div>
                            <div class="featured-desc">
                                <p>Our legal consultants help startups tackle legal challenges, specializing in contracts, banking, and taxes. Our compliance advisors keep startups informed about regulations, ensuring alignment with laws and company policies.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1000">
                    
                    <div class="featured-icon-box icon-align-top-content style2 active1">
                        <div class="featured-icon">
                            <div class="prt-icon prt-icon_element-onlytxt prt-icon_element-size-sm prt-icon_element-color-whitecolor">
                            <i class="flaticon flaticon-cash-flow"></i>
                            </div>
                        </div>
                        <div class="featured-content">
                            <div class="featured-title">
                                <h3><a href="#">Funding</a></h3>
                            </div>
                            <div class="featured-desc">
                                <p>We understand the importance of financial support for startups. We help connect startups withpotential investors and provide guidance and assistance in raising funds to fuel their growth.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200">
                    
                    <div class="featured-icon-box icon-align-top-content style2">
                        <div class="featured-icon">
                            <div class="prt-icon prt-icon_element-onlytxt prt-icon_element-size-sm prt-icon_element-color-whitecolor">
                            <i class="flaticon flaticon-leadership"></i>
                            </div>
                        </div>
                        <div class="featured-content">
                            <div class="featured-title">
                                <h3><a href="#">Scaling Businesses</a></h3>
                            </div>
                            <div class="featured-desc">
                                <p>Pixalive Ventures helps small businesses in both innovating for growth and navigating challenges within a dynamic economy.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
  </> 
  ); 
  } 
  export default Startups;